import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PostHeader from './PostHeading';
import CallToAction from '../CallToAction';

const PostCardStyles = styled.article`
  padding-bottom: 30px;
  padding-top: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:first-of-type {
    padding-top: 0;
  }

  .post-blurb p {
    font-size: 14px;
    color: #555;
  }
`;

export default function PostCard({ title, featuredImage, slug, date, blurb }) {
  const image = getImage(featuredImage);

  return (
    <PostCardStyles>
      <GatsbyImage image={image} alt={title} />

      <PostHeader title={title} date={date} comp />

      <div className="post-blurb">
        <p>{blurb} ...</p>
      </div>

      <Link to={`/news/${slug}`}>
        <CallToAction>Read More</CallToAction>
      </Link>
    </PostCardStyles>
  );
}

PostCard.propTypes = {
  title: PropTypes.string.isRequired,
  featuredImage: PropTypes.any.isRequired,
  slug: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  blurb: PropTypes.string.isRequired,
};
