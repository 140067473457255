import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PageGrid from '../components/layout/PageGrid';
import SidebarContact from '../components/widgets/sidebarContact';
import PostCard from '../components/blog/PostCard';
import Breadcrumb from '../components/layout/Breadcrumb';

const NewsPageStyles = styled.div`
  .content {
    padding: 60px 0;
  }
`;

export default function NewsPage({ data }) {
  const { posts } = data.articles;

  // if no posts found, return a message
  if (!!posts.length === false) {
    return (
      <>
        <Breadcrumb>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/news">News</Link>
          </li>
        </Breadcrumb>
        <div
          className="container"
          style={{ textAlign: 'center', padding: '60px 0 160px 0' }}
        >
          <p>There is currently no news</p>
        </div>
      </>
    );
  }

  return (
    <NewsPageStyles>
      <Breadcrumb>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/news">News</Link>
        </li>
      </Breadcrumb>

      <div className="container content">
        <PageGrid reversed={false}>
          <div className="posts">
            {posts.map((post) => (
              <PostCard
                key={post.id}
                title={post.heading}
                featuredImage={post.featuredImage.asset}
                slug={post.slug.current}
                date={post._createdAt}
                blurb={post.blurb.en}
              />
            ))}
          </div>

          <div className="sidebar">
            <SidebarContact />
          </div>
        </PageGrid>
      </div>
    </NewsPageStyles>
  );
}

export const query = graphql`
  {
    articles: allSanityArticle(sort: { fields: _createdAt, order: DESC }) {
      posts: nodes {
        _createdAt
        id
        heading
        blurb {
          en
        }
        slug {
          current
        }
        featuredImage {
          asset {
            gatsbyImageData(layout: FULL_WIDTH, width: 814, height: 385)
          }
        }
      }
    }
  }
`;

NewsPage.propTypes = {
  data: PropTypes.object.isRequired,
};
