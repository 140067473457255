import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const PageGridStyles = styled.div`
  &.grid {
    display: grid;
    grid-template-columns: 3.5fr 1fr;
    grid-gap: 50px;
  }

  &.grid-reverse {
    grid-template-columns: 1fr 3.5fr;
    grid-auto-flow: dense;
  }

  @media only screen and (max-width: 780px) {
    &.grid {
      grid-template-columns: 1fr;
      grid-auto-flow: dense;
    }
  }
`;

export default function PageGrid({ children, reversed }) {
  const classes = reversed ? 'grid grid-reversed' : 'grid';

  return <PageGridStyles className={classes}>{children}</PageGridStyles>;
}

PageGrid.propTypes = {
  children: PropTypes.any.isRequired,
  reversed: PropTypes.bool.isRequired,
};
